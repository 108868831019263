import React from "react";
import Layout from "../../containers/Layout";
import { Contact } from "../../components";
import FaqPayment from "../../components/FaqsComponents/FaqPayment";

export default function General() {
  return (
    <Layout title={"FAQs"}>
      <FaqPayment />
      <Contact />
    </Layout>
  );
}
